<loading-status></loading-status>
@if (article$ | async; as organization) {

@if (organization.body) {
    <ng-scrollbar class="ngr-scrollbar" visibility="visible">
        <div>
                @if (!creditorMode) {
                    <button class="btn btn-gradient btn-block float-end mt-1 me-1" (click)="startDownload(organization.id)">Download</button>
                }
                <h1 [innerHTML]="organization.title"></h1>
            </div>
        <app-organization-overview [OrganizationArticle]="organization" [creditorMode]="creditorMode"></app-organization-overview>
        <app-download-dialog [action]="downloadAction"></app-download-dialog>
    </ng-scrollbar>
    }
}