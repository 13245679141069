import { Component, Input } from '@angular/core'
import { NgxBootstrapIconsModule } from 'ngx-bootstrap-icons'
import { AsyncPipe, } from '@angular/common'
import { NgScrollbarModule } from 'ngx-scrollbar'
import { LoadingStatusComponent } from 'src/app/shared/components/loading-status/loading-status.component'
import { OrganizationComponent } from 'src/app/pages/organizations'
import { OrganizationOverviewComponent } from 'src/app/shared/components/organization-overview/organization-overview.component'
import { DownloadDialogComponent } from 'src/app/shared/components/download-dialog/download-dialog.component'

@Component({
    selector: 'app-creditor-article-via-organization',
    imports: [
        NgxBootstrapIconsModule,
        AsyncPipe,
        NgScrollbarModule,
        LoadingStatusComponent,
        OrganizationOverviewComponent,
        DownloadDialogComponent
    ],
    templateUrl: '../../../organizations/organizations-article/organization-article.component.html',
    styleUrl: '../../../organizations/organizations-article/organization-article.component.scss'
})
export class CreditorArticleViaOrganizationComponent extends OrganizationComponent {

  @Input() override sections: string[] = [
  ]
  @Input() expandAll = false
  @Input() printMode = false

  constructor() {
    super()
    this.area = 'organizations-via-creditors'
    this.creditorMode = true
  }
}
